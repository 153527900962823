import styled from 'styled-components';
import {
  theme,
  media,
  ContentBlock,
} from '@bitcoin-portal/bitcoincom-storybook';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  & > input {
    width: 100%;
    margin-top: ${theme.spacing.unit * 2}px;
  }

  ${media.sm`
    & > input {
      max-width: 450px;
      margin-top: ${theme.spacing.unit * 4}px;
    }
  `};
`;

export const Address = styled.div`
  margin-bottom: ${theme.spacing.unit * 2}px;
  word-break: break-all;
`;

export const Content = styled.div`
  max-width: 600px;
  padding-top: ${theme.spacing.unit * 2}px;

  ${media.sm`
    padding-top: ${theme.spacing.unit * 4}px;
  `};

  & > div:first-child {
    margin-bottom: ${theme.spacing.unit * 2}px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-bottom: ${theme.spacing.unit * 8}px;

  & > div:first-child {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
  }

  & > section {
    padding: 0;
  }

  & > section > div {
    min-height: unset;
  }

  & > section > div > div {
    padding: 0;
    margin: 0;
  }

  & > div > input {
    max-width: 400px;
    margin: auto;
    background-color: white;
  }

  ${media.sm`
    margin-bottom: ${theme.spacing.unit * 16}px;
  `};
`;

export const CustomContentBlock = styled(ContentBlock)`
  padding-left: 0;
  padding-right: 0;
`;
