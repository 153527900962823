import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  H1,
  Paragraph,
  ContentBlock,
} from '@bitcoin-portal/bitcoincom-storybook';

const Header = () => (
  <ContentBlock hero>
    <H1>
      <FormattedMessage id="cash-address-converter.header.title" />
    </H1>
    <Paragraph center>
      <FormattedMessage id="cash-address-converter.header.text" />
    </Paragraph>
  </ContentBlock>
);

export default Header;
